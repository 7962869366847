import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/home.vue'),
    children: [
      {
        path: '/',
        name: 'mall',
        component: () => import('@/views/mall.vue'),
      },
      {
        path: '/shop-all',
        name: 'shopAll',
        component: () => import('@/views/shopAll.vue'),
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('@/views/detail.vue'),
      },
      {
        path: '/addToCard',
        name: 'addToCard',
        component: () => import('@/views/addToCard.vue'),
      }
    ]
  },
  {
    path: '/card',
    name: 'card',
    component: () => import('@/views/card.vue'),
  }
]

const router = new VueRouter({
  routes
})

export default router
